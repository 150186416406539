@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:700,900');

:root {
  --primary-color: #CC3300;
  --secondary-color: #8B0400;
  --terciary-color: #CC3200;
  --four-color: #B3B3B3;
  --five-color: #E85D04;
  --six-color: #370617;
  --primary-font: 'Montserrat', sans-serif;
  --secondary-font: 'Nunito Sans', sans-serif;
}

body {
  margin: 0;
  background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);

}


/*  HOME  */

.home {
  display: flex;
  flex-direction: column;
  z-index: 4;
}
.whatsapp {
  position: fixed;
  bottom: 20px; /* Ajusta la distancia desde la parte inferior según tu preferencia */
  right: 20px; /* Ajusta la distancia desde la derecha según tu preferencia */
  z-index: 999; /* Asegura que esté por encima de otros elementos */
  padding: 1px; /* Ajusta el tamaño del botón según tu preferencia */
  border-radius: 50%; /* Hace que el botón sea circular */
  cursor: pointer;
}


/*  NAVBAR  */

.burgerNav {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}
.logo {
  position: absolute;
  margin-left: .5rem;
  padding: .5rem;
  top: 5px;
}
.burgerNav h1 {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 11px;
  font-size: 1.5rem;
  font-weight: 200;
  margin-left: 2rem;
}  

#toggle {
  display: none;
}

/**    Hamburger  **/

.hamburger {
  position: fixed;
  top: 5em;
  right: 5%;
  margin-left: -2em;
  margin-top: -45px;
  width: 2em;
  height: 45px;
  z-index: 5;
  cursor: pointer;
}

.hamburger div {
  position: relative;
  width: 3em;
  height: 2px;
  border-radius: 3px;
  background-color: #ffffff;
  margin-top: 8px;
  transition: all 0.3s ease-in-out;
}
.hamburger > .meat  {
  width: 100%;
  left: 50%;
}

/**  Nav Styles  **/

.nav {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000000ef;
  top: -100%; left: 0; right: 0; bottom: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  transform: scale(0);
  z-index: 1;
}
.nav-wrapper {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}
nav {
  text-align: left;
  margin-left: 25%;
}
nav a {
  position: relative;
  text-decoration: none;
  color: #ffffff;
  font-size: 2rem;
  display: inline-block;
  margin-top: 1.25em;
  transition: color 0.2s ease-in-out;
  letter-spacing: 1px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}
nav a:before {
  content: '';
  height: 0;
  position: absolute;
  width: 0.25em;
  background-color: var(--terciary-color);
  left: -0.5em;
  transition: all 0.2s ease-in-out;
}
nav a:hover {
  color: var(--terciary-color);
}
nav a:hover:before {
  height: 100%;
}

/**
Animations
**/
#toggle:checked + .hamburger .top-bun {
  transform: rotate(-45deg);
  margin-top: 25px;
}
#toggle:checked + .hamburger .bottom-bun {
  opacity: 0;
  transform: rotate(45deg);
}
#toggle:checked + .hamburger .meat {
  transform: rotate(45deg);
  margin-top: -4px;
  width: 150%;
  left: -1%;
}

#toggle:checked + .hamburger + .nav {
  top: 0;
  transform: scale(1);
}

/*  HEADER  */

.video {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 40%;
  height: 150%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 50%;
}

.Iam {
  font-family: 'Montserrat', sans-serif;
  color: white;
  text-align: center; /* Centrar el texto horizontalmente */
  position: absolute; /* Posición absoluta para centrar verticalmente */
  top: 40%; /* Alinear el borde superior del elemento al 50% del contenedor principal */
  left: 50%; /* Alinear el borde izquierdo del elemento al 50% del contenedor principal */
  transform: translate(-50%, -50%); /* Mover el elemento hacia arriba y hacia la izquierda en un 50% de su propio tamaño */
}

/* Aesthetics */

.bottom-section{
  background: #d4fef2;
}
.section h1{
  font-size: 85px;
  letter-spacing: 3px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}
.section p,
.section a{
  font-weight: 800;
  letter-spacing: 9px;
  text-transform: uppercase;
  text-align: center;
}

.content-theme-dark h1{
  background-color: linear-gradient(135deg, #f4583a 0%,#cd1e38 100%);
  text-shadow: 0 20px 40px rgba(0,0,0,.5);
}
.content-theme-dark p,
.content-theme-dark a{
  color: #a8a8a8;
}
.content-theme-light h1{
  color:#062930;
  text-shadow: 0 20px 40px rgba(0,0,0,.5);
}
.content-theme-light p,
.content-theme-light a{
  color: #728E93;
}


.mouse {
  display: block;
  margin: 0 auto;
  width: 26px;
  height: 46px;
  border-radius: 13px;
  border: 3px solid white;
  position: absolute;
  bottom: 40px;
  position: absolute;
  left: 51%;
  margin-left: -26px;
}
.mouse > span {
  display: block;
  margin: 6px auto;
  width: 2px;
  height: 2px;
  border-radius: 4px;
  background: white;
  border: 1px solid transparent;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}
@-webkit-keyframes scroll {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
}

/*  INFO  */

.info {
 margin-top: 43rem;
 background-image: url("../src/assets/fondo-prueba.png");
 background-repeat: no-repeat;
 background-size: cover;
 padding: 1rem;
}
.info p {
  font-size: 1.2rem;
  width: 80%;
  padding: 1rem;
  color: rgb(0, 0, 0);
  margin: 1px 0;      
  appearance: none;
	border-radius: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
.info a {
  text-decoration: none;
  color: #ffffff;
}
.info a:hover {
  text-decoration: none;
}
.info .box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  cursor: pointer;
  color: white;
  background-color: rgb(0, 0, 0);
  margin-bottom: 1rem;
  width: 150px;
  height: 50px;
  border: 1px solid #000000;
  box-shadow: 5px 5px #292929;
  transition: background-color .3s ease-in-out;
  transition: box-shadow .3s ease-in-out;
}
.info .box:hover {
  background: #b9b9b9;
  border: 1px solid #000000;
  box-shadow: 5px 5px #818181;
  transition: background .5s ease-in-out;
  transition: box-shadow .5s ease-in-out;
}
.info span {
  color: #d34c65;
}
.description {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
}
.description1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
  margin-top: 4rem;
}

/*  GALERIA  */

.gallery {
  margin: 0;
  min-height: 100vh;
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  display: flex;
  flex-direction: column;
  position: relative; /* Establecer la posición relativa */
}
.video-overlay {
  position: absolute; /* Posición absoluta */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.2; /* Opacidad del video */
}

.video-background {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajustar el video para cubrir todo el contenedor */
}
.gallery-hijo {
  display: flex;
  flex-direction: column;
}
.gallery-hijo2 {
  display: flex;
}
.gallery-hijo h2 {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  padding: 1rem;
  font-size: 2.2rem;
}
.titleGallery {
  color: white;
  text-align: center;
  font-family: var(--primary-font);
}
.resaltado {
  color: #b9b9b9;
}

figure {
  display: grid;
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
}
figure > * {
  grid-area: 1/1;
  transition: .4s;
}
figure figcaption {
  display: grid;
  align-items: end;
  font-family: sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #0000;
  padding: .75rem;
  background: var(--c,#0009);
  clip-path: inset(0 var(--_i,100%) 0 0);
  --webkit-mask:
    linear-gradient(#fff5 0 0),
    linear-gradient(#000 0 0);
  --webkit-mask-composite: xor;
  --webkit-mask-clip: text, padding-box;
  margin: -1px;
}
figure:hover figcaption, p {
  --_i: 0%;
}
figure:hover img {
  transform: scale(1.2);
}
figure figcaption {
   --webkit-mask: none;
   color: #b9b9b9;
  }

/*  REDES  */

.redes {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
}
.redes-hijo {
  display: flex;
  flex-direction: row;
}
h3 {
  font-family: 'Montserrat', sans-serif;
  color: white;
}
.cuadrado {
  width: 50px; 
  height: 50px; 
  border-radius:3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff4f;
  transition: background .5s;
  margin-right: 5px;
  cursor: pointer;
}
.cuadrado:hover {
  background: #ffffffb4;
  transition: background .5s;
}


/*  FOOTER  */

.footer-padre {
  background-color: #000000;
  padding: 4rem;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: .6rem;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  opacity: 25%;
}
footer {
  text-align: center;
  margin-top: 3rem;
  opacity: 35%;
}
footer span {
  color: #02DF71 !important;
  cursor: pointer;
}
.footer-menu {
text-align: center;
margin-bottom: 3rem;
}
.footer-menu a {
  text-decoration: none !important;
  color: #d34c65;
}
.footer-menu3 {
  text-align: center;
  margin-bottom: 3rem;
  }
  .footer-menu3 a {
    text-decoration: none !important;
    color: #d34c65;
  }
a {
  text-decoration: none !important;
}
.footer-hijo {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer-hijo p {
  text-align: center;
}
.footer-hijo .arrow {
  filter: invert(100%);
  cursor: pointer;
}
.text-footer {
  text-align: center;
}
.linkPixel {
  color: #d34c65;
}

 /*  ENVIADO  */

 .enviado {
  margin: 0;
  background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 90vh;
  height: 100%;
  width: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleEnviado {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  padding: 1rem;
  margin-bottom: -9rem;
  font-size: 2.2rem;
  margin-left: 2rem;
  text-align: center;
  height: 60vh;
}

.btnEnviado {
  color: rgb(0, 0, 0);;
  background-color: #b9b9b9;
  padding: .5rem;
  border-radius: .5rem;
  border: none;
  font-weight: bold;
  font-family: var(--primary-font);
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 5px 20px pink;
  transition: box-shadow .3s ease-in-out;
}
.btnEnviado:hover {
  background-color: #b9b9b9;
  color: rgb(255, 255, 255);
  box-shadow: none;
  transition: box-shadow .3s ease-in-out;
}
.contenedorBtn {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
@media (max-width: 699px) {
  .titleEnviado {
    font-size: 1.5rem;
  }  
}

/*  ----------------------RESPONSIVE------------------------  */


/*  RESOLUCIÓN 320px  */

@media (max-width: 320px) {
  .whatsapp {
    bottom: 30px !important; /* Ajusta la distancia desde la parte inferior según tu preferencia */    
  }
  nav a {
    font-size: 1rem;
  }
  .video {
    height: 190%;
  }
  .section h1{
    font-size: 25px !important;
  }
  .info {
    flex-direction: column;
  }
  .description {
    flex-direction: column;
  }
  .description1 {
    flex-direction: column;
  }
  h3 {
    text-align: center;
  }
  .gallery-hijo2 {
    display: flex;
    flex-direction: column;
  }
  .footer-hijo {
    flex-direction: column;
  }
}



/*  RESOLUCIÓN 768px  */

@media (max-width: 768px) {
  .whatsapp {
    bottom: 30px !important; /* Ajusta la distancia desde la parte inferior según tu preferencia */    
  }
  nav a {
    font-size: 1rem;
  }
  section h1{
    font-size: 25px !important;
  }
  .video {
    height: 190%;
  }
  .info {
    flex-direction: column;
  }
  .gallery-hijo2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .description {
    flex-direction: column;
  }
  .description1 {
    flex-direction: column;
  }
  h3 {
    text-align: center;
  }
  .footer-hijo {
    flex-direction: column;
  }
}

/*  RESOLUCIÓN 1024px  */

@media (max-width: 1024px) {
  .whatsapp {
    bottom: 30px !important; /* Ajusta la distancia desde la parte inferior según tu preferencia */    
  }
  nav a {
    font-size: 1.5rem;
  }
  section h1{
    font-size: 25px !important;
  }
  .video {
    height: 190%;
  }
  .info {
    flex-direction: column;
  }
  .gallery-hijo2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .description {
    flex-direction: column;
  }
  .description1 {
    flex-direction: column;
  }
  h3 {
    text-align: center;
  }
  .footer-hijo {
    flex-direction: column;
  }
}

/*  RESOLUCIÓN 1440px  */

@media (max-width: 1440px) {
  .video {
    height: 190%;
  }
}

/*  RESOLUCIÓN 2560px  */

@media (max-width: 2560px) {
  .video {
    height: 200%;
  }
}