@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.contactar {
	font-family: "DM Sans", sans-serif;
	line-height: 1.5;
	background-image: url("../assets/fondo-edificios.png");
	opacity: 90%;
	padding: 0 2rem;
}

img {
	max-width: 100%;
	display: block;
}


// iOS Reset 
input {
	appearance: none;
	border-radius: 0;
    font-family: 'Montserrat', sans-serif;
}

.card {
	margin: 2rem auto;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 425px;
	background-color: #FFF;
	border-radius: 10px;
	box-shadow: 0 10px 20px 0 rgba(#999, .25);
	padding: .75rem;
}

.card-image {
	border-radius: 8px;
	overflow: hidden;
	padding-bottom: 65%;
	background-image: url('../assets/img-form.png');
	background-repeat: no-repeat;
	background-size:contain;
	background-position: 0 5%;
	position: relative;
}

.card-heading {
	position: absolute;
	left: 10%;
	top: 15%;
	right: 10%;
	font-size: 1.75rem;
	font-weight: 700;
	color: #d34c65;
	line-height: 1.222;
	small {
		display: block;
		font-size: .75em;
		font-weight: 400;
		margin-top: .25em;
	}
}

.card-form {
	padding: 2rem 1rem 0;
}

.input {
	display: flex;
	flex-direction: column-reverse;
	position: relative;
	padding-top: 1.5rem;
	&+.input {
		margin-top: 1.5rem;
	}
}

.input-label {
	color: #8597a3;
	position: absolute;
	top: 1.5rem;
	transition: .25s ease;
}

.input-field {
	border: 0;
	z-index: 1;
	background-color: transparent;
	border-bottom: 2px solid #eee; 
	font: inherit;
	font-size: 1.125rem;
	padding: .25rem 0;
	&:focus, &:valid {
		outline: 0;
		border-bottom-color: #330867;
		&+.input-label {
			color: #330867;
			transform: translateY(-1.5rem);
		}
	}
}

.action {
	margin-top: 2rem;
}

.action-button {
	font: inherit;
	font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
	padding: 1em;
	width: 100%;
	font-weight: 500;
	background-color: #000000;
	transition: background-color .5s;
	border-radius: 6px;
	color: #FFF;
	border: 0;
	&:focus {
		outline: 0;
	}
}
.action-button:hover {
	background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
	transition: background-image .5s;
}

.card-info {
	padding: 1rem 1rem;
	text-align: center;
	font-size: .875rem;
	color: #8597a3;
	a {
		display: block;
		color: #CC3300;
		text-decoration: none;
	}
}
@media (max-width: 320px) {
	.card-heading {
		font-size: 1.2rem;
	  }
	  .input-label {
		color: #8597a3;
		position: absolute;
		top: .8rem;
		transition: .25s ease;
	}
}
@media (max-width: 375px) {
	.card-heading {
		font-size: 1.2rem;
	  }
	  .input-label {
		color: #8597a3;
		position: absolute;
		top: .8rem;
		transition: .25s ease;
	}
}
